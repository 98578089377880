import { periodToString, type Period } from '@orus.eu/period'
import { generateTypeIdFromString } from '@orus.eu/utils'

/**
 * Generate the id of a main invoice.
 * Id is deterministic and uses period bounds as a way to help prevent a few mistakes such as creating two main invoices for the same period
 */
export function generateMainInvoiceId(subscriptionId: string, period: Period): string {
  return generateTypeIdFromString('inv', `${subscriptionId}-${periodToString(period)}`).toString()
}

/**
 * Generate the id of an adjustment invoice.
 * Id is deterministic and uses the adjusted invoice id and the number of existing adjustments for this invoice
 * to help prevent mistakes such as creating the same adjustment multiple times because of a race condition.
 */
export function generateAdjustmentInvoiceId(
  adjustedInvoiceId: string,
  existingV2Invoices: Array<{ adjustedInvoiceId: string | undefined }>,
): string {
  return generateTypeIdFromString(
    'inv',
    `adjustment-${adjustedInvoiceId}-${getExistingAdjustmentsCount(existingV2Invoices, adjustedInvoiceId)}`,
  ).toString()
}

function getExistingAdjustmentsCount(
  existingInvoices: Array<{ adjustedInvoiceId: string | undefined }>,
  invoiceId: string,
) {
  return existingInvoices.filter((invoice) => invoice.adjustedInvoiceId === invoiceId).length
}
