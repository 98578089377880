import { addAmounts, type Amount, amountToNumber, multiplyByNumber, substractAmounts } from '@orus.eu/amount'
import type { InvoiceDataV2, InvoicingItemProductDetail } from './invoice-types'

/**
 * Scales the invoice data to meet the new total premium, while preserving the
 * relations between the different fields.
 * This API takes a target premium and not a scaling factor, to avoid instabilities
 * due to rounding errors.
 */
export function scaleInvoiceData(invoiceData: InvoiceDataV2, newTotalPremium: Amount): InvoiceDataV2 {
  const { scaledFields, scalingFactor } = scaleAmountFields(invoiceData, newTotalPremium)
  return {
    ...invoiceData,
    ...scaledFields,
    breakdownByProduct: Object.fromEntries(
      Object.entries(invoiceData.breakdownByProduct).map(([product, productDetail]) => [
        product,
        scaleProductDetail(productDetail, scalingFactor),
      ]),
    ),
  }
}

/**
 * Same as scaleInvoiceData, but works with numbers.
 */
export function scaleInvoiceDataAccurately(
  invoiceData: InvoiceDataV2<number>,
  newTotalPremium: number,
): InvoiceDataV2<number> {
  const { scaledFields, scalingFactor } = scaleAmountFieldsAccurately(invoiceData, newTotalPremium)
  return {
    ...invoiceData,
    ...scaledFields,
    breakdownByProduct: Object.fromEntries(
      Object.entries(invoiceData.breakdownByProduct).map(([product, productDetail]) => [
        product,
        scaleProductDetailAccurately(productDetail, scalingFactor),
      ]),
    ),
  }
}

export type ScalableInvoiceDataFields<AMOUNT_TYPE = Amount> = Pick<
  InvoiceDataV2<AMOUNT_TYPE>,
  | 'premiumWithoutTaxes'
  | 'insuranceTaxes'
  | 'terrorismTax'
  | 'assistanceVAT'
  | 'assistancePremiumWithoutTaxes'
  | 'totalPremium'
  | 'orusInstallmentFee'
  | 'orusManagementFee'
  | 'partnerManagementFee'
  | 'partnerApplicationFee'
>

export function scaleAmountFields(
  invoiceData: ScalableInvoiceDataFields,
  newTotalPremium: Amount,
): { scaledFields: ScalableInvoiceDataFields; scalingFactor: number } {
  const scalingFactor = amountToNumber(newTotalPremium) / amountToNumber(invoiceData.totalPremium)

  const remainingTerrorismTax = invoiceData.terrorismTax
  const remainingInsuranceTaxes = multiplyByNumber(invoiceData.insuranceTaxes, scalingFactor)

  const remainingPremiumWithoutTaxes = substractAmounts(
    newTotalPremium,
    addAmounts(remainingTerrorismTax, remainingInsuranceTaxes),
  )

  return {
    scaledFields: {
      premiumWithoutTaxes: remainingPremiumWithoutTaxes,
      insuranceTaxes: remainingInsuranceTaxes,
      terrorismTax: remainingTerrorismTax,
      assistanceVAT: multiplyByNumber(invoiceData.assistanceVAT, scalingFactor),
      assistancePremiumWithoutTaxes:
        invoiceData.assistancePremiumWithoutTaxes == undefined
          ? undefined
          : multiplyByNumber(invoiceData.assistancePremiumWithoutTaxes, scalingFactor),
      totalPremium: newTotalPremium,
      orusInstallmentFee: multiplyByNumber(invoiceData.orusInstallmentFee, scalingFactor),
      orusManagementFee: multiplyByNumber(invoiceData.orusManagementFee, scalingFactor),
      partnerManagementFee: multiplyByNumber(invoiceData.partnerManagementFee, scalingFactor),
      partnerApplicationFee: multiplyByNumber(invoiceData.partnerApplicationFee, scalingFactor),
    },
    scalingFactor,
  }
}

export function scaleAmountFieldsAccurately(
  invoiceData: ScalableInvoiceDataFields<number>,
  newTotalPremium: number,
): { scaledFields: ScalableInvoiceDataFields<number>; scalingFactor: number } {
  const scalingFactor = newTotalPremium / invoiceData.totalPremium

  const remainingTerrorismTax = invoiceData.terrorismTax
  const remainingInsuranceTaxes = invoiceData.insuranceTaxes * scalingFactor

  const remainingPremiumWithoutTaxes = newTotalPremium - (remainingTerrorismTax + remainingInsuranceTaxes)

  return {
    scaledFields: {
      premiumWithoutTaxes: remainingPremiumWithoutTaxes,
      insuranceTaxes: remainingInsuranceTaxes,
      terrorismTax: remainingTerrorismTax,
      assistanceVAT: invoiceData.assistanceVAT * scalingFactor,
      assistancePremiumWithoutTaxes:
        invoiceData.assistancePremiumWithoutTaxes == undefined
          ? undefined
          : invoiceData.assistancePremiumWithoutTaxes * scalingFactor,
      totalPremium: newTotalPremium,
      orusInstallmentFee: invoiceData.orusInstallmentFee * scalingFactor,
      orusManagementFee: invoiceData.orusManagementFee * scalingFactor,
      partnerManagementFee: invoiceData.partnerManagementFee * scalingFactor,
      partnerApplicationFee: invoiceData.partnerApplicationFee * scalingFactor,
    },
    scalingFactor,
  }
}

function scaleProductDetail(
  productDetail: InvoicingItemProductDetail | undefined,
  remainingFactor: number,
): InvoicingItemProductDetail | undefined {
  return productDetail
    ? {
        premiumWithoutTaxes: multiplyByNumber(productDetail.premiumWithoutTaxes, remainingFactor),
        insuranceTaxes: multiplyByNumber(productDetail.insuranceTaxes, remainingFactor),
        otherTaxes: multiplyByNumber(productDetail.otherTaxes, remainingFactor),
        orusFee: multiplyByNumber(productDetail.orusFee, remainingFactor),
        orusInstallmentFee: multiplyByNumber(productDetail.orusInstallmentFee, remainingFactor),
        orusManagementFee: multiplyByNumber(productDetail.orusManagementFee, remainingFactor),
        partnerManagementFee: multiplyByNumber(productDetail.partnerManagementFee, remainingFactor),
      }
    : undefined
}

function scaleProductDetailAccurately(
  productDetail: InvoicingItemProductDetail<number> | undefined,
  remainingFactor: number,
): InvoicingItemProductDetail<number> | undefined {
  return productDetail
    ? {
        premiumWithoutTaxes: productDetail.premiumWithoutTaxes * remainingFactor,
        insuranceTaxes: productDetail.insuranceTaxes * remainingFactor,
        otherTaxes: productDetail.otherTaxes * remainingFactor,
        orusFee: productDetail.orusFee * remainingFactor,
        orusInstallmentFee: productDetail.orusInstallmentFee * remainingFactor,
        orusManagementFee: productDetail.orusManagementFee * remainingFactor,
        partnerManagementFee: productDetail.partnerManagementFee * remainingFactor,
      }
    : undefined
}
