import { formatYyyyMmDd, getCalendarDateFromTimestamp, PARIS } from '@orus.eu/calendar-date'

export type Period = {
  periodStartTimestamp: number
  periodEndTimestamp: number
}

export function periodToString(period: Period): string {
  const formattedStartDate = formatYyyyMmDd(getCalendarDateFromTimestamp(period.periodStartTimestamp, PARIS))
  const formattedEndDate = formatYyyyMmDd(getCalendarDateFromTimestamp(period.periodEndTimestamp, PARIS))
  return `${formattedStartDate}-${formattedEndDate}`
}
