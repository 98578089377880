import { typeid, TypeID } from 'typeid-js'
import { v5 } from 'uuid'

export function generateTypeIdFromString<Prefix extends string>(prefix: Prefix, value: string): TypeID<Prefix> {
  const valueUuid = v5(value, NAMESPACE_UUID)
  return TypeID.fromUUID(prefix, valueUuid)
}

export function generateTypeId<Prefix extends string>(prefix: Prefix): TypeID<Prefix> {
  return typeid(prefix)
}

/**
 * The UUID namespace for typeIds, never change this value because some typeIds must be generated in a deterministic way.
 */
const NAMESPACE_UUID = '6ba7b810-9dad-11d1-80b4-00c04fd430c8'
