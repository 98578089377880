import { css } from '@emotion/react'
import { amountToString, type Amount } from '@orus.eu/amount'
import type { CreateInvoiceV2Payload } from '@orus.eu/backend/src/invoicing/routers/invoices'
import type { InvoiceDataV2 } from '@orus.eu/invoice'

import type { Invoice } from '@orus.eu/backend/src/invoicing/views/invoice-model'
import {
  PARIS,
  formatTimestampDdMmYyyy,
  getCalendarDateFromTimestamp,
  getStartOfCalendarDate,
  getZonedDateTimeFromMillis,
  type CalendarDate,
} from '@orus.eu/calendar-date'
import { generateAdjustmentInvoiceId, generateMainInvoiceId, scaleInvoiceData } from '@orus.eu/invoice'
import { type Period } from '@orus.eu/period'
import {
  Button,
  Card,
  CheckboxContainer,
  ContentContainerBackoffice,
  DatepickerFormField,
  Dialog,
  Divider,
  DropdownFormField,
  Text,
  TextInputLabelWrapper,
  ValidatedTextField,
  enqueueTemporaryNotificationAlert,
  spacing,
  useAsyncCallback,
  useDialogVisibility,
} from '@orus.eu/pharaoh'
import {
  ValidationDialogDivider,
  ValidationDialogRowContent,
} from '@orus.eu/pharaoh/src/components/features/iban-wizard/common/SepaMandateValidationDialog'
import type { OrganizationType } from '@orus.eu/right-access-management'
import { useNavigate, useParams } from '@tanstack/react-router'
import { memo, useCallback, useState } from 'react'
import { trpc, trpcReact } from '../../../../client'
import { amountCustomInputProps, amountMapperWithDecimals } from '../../../../lib/amount'
import { assert } from '../../../../lib/errors'
import { BackofficeSectionTitle } from '../../../atoms/backoffice-section-title'

import type { InvoicingItem } from '@orus.eu/backend/src/invoicing/views/invoice-payment-status-view'
export default function PlatformNewInvoicePageV2(): JSX.Element {
  const { subscriptionId } = useParams({ from: '/bak/contracts/$subscriptionId/new-invoice' })
  assert(subscriptionId, 'This page required a subscriptionId param')
  const [organizationType] =
    trpcReact.organizations.getOrganizationTypeForSubscriptionId.useSuspenseQuery(subscriptionId)
  const [existingInvoices] = trpcReact.invoices.listInvoices.useSuspenseQuery(subscriptionId)

  const now = getZonedDateTimeFromMillis(Date.now(), PARIS).toMillis()

  const [issueTimestamp, setIssueTimestamp] = useState<number | null>(now)
  const [dueTimestamp, setDueTimestamp] = useState<number | null>(null)
  const [periodStartTimestamp, setPeriodStartTimestamp] = useState<number | null>(null)
  const [periodEndTimestamp, setPeriodEndTimestamp] = useState<number | null>(null)
  const [isFirstOfYear, setIsFirstOfYear] = useState<boolean>(false)
  const [isInFirstYear, setIsInFirstYear] = useState<boolean>(false)
  const [totalPremium, setTotalPremium] = useState<Amount | null>(null)
  const [theoreticalInvoiceData, setTheoreticalInvoiceData] = useState<InvoiceDataV2 | null>(null)

  const [contractVersionIdsWithLabel] =
    trpcReact.contracts.getContractVersionIdsWithLabel.useSuspenseQuery(subscriptionId)

  const contractVersionIds = contractVersionIdsWithLabel.map(([id]) => id)

  const [contractVersionId, setContractVersion] = useState<string | null>(
    contractVersionIds[contractVersionIds.length - 1],
  )

  const handleIssueCalendarDateChange = (value: CalendarDate | null) => {
    const newIssueTimestamp = value ? getStartOfCalendarDate(value, PARIS).toMillis() : null
    setIssueTimestamp(newIssueTimestamp)
  }

  const handleDueCalendarDateChange = (value: CalendarDate | null) => {
    const newDueTimestamp = value ? getStartOfCalendarDate(value, PARIS).toMillis() : null
    setDueTimestamp(newDueTimestamp)
  }

  const handleFirstPeriodDayCalendarDateChange = (value: CalendarDate | null) => {
    const newPeriodStartTimestamp = value ? getStartOfCalendarDate(value, PARIS).toMillis() : null
    setPeriodStartTimestamp(newPeriodStartTimestamp)
  }

  const handleLastPeriodDayCalendarDateChange = (value: CalendarDate | null) => {
    const newPeriodEndTimestamp = value ? getStartOfCalendarDate(value, PARIS).plus({ day: 1 }).toMillis() : null
    setPeriodEndTimestamp(newPeriodEndTimestamp)
  }

  const handleContractVersionChange = (value: string | null) => {
    setContractVersion(value)
  }

  const handleTotalPremiumChange = useCallback((value: Amount | null | undefined) => {
    setTotalPremium(value ?? null)
  }, [])

  const issueCalendarDate = issueTimestamp ? getCalendarDateFromTimestamp(issueTimestamp, PARIS) : null
  const dueCalendarDate = dueTimestamp ? getCalendarDateFromTimestamp(dueTimestamp, PARIS) : null
  const firstPeriodDayCalendarDate = periodStartTimestamp
    ? getCalendarDateFromTimestamp(periodStartTimestamp, PARIS)
    : null

  const lastPeriodDayCalendarDate = periodEndTimestamp
    ? getCalendarDateFromTimestamp(
        getZonedDateTimeFromMillis(periodEndTimestamp, PARIS).minus({ day: 1 }).toMillis(),
        PARIS,
      )
    : null

  const adjustedInvoice =
    periodStartTimestamp == undefined || periodEndTimestamp == undefined
      ? undefined
      : existingInvoices.find(
          ({ invoice }) =>
            !isAdjustementInvoice(invoice) &&
            invoicePeriodMatches(invoice, { periodStartTimestamp, periodEndTimestamp }),
        )?.invoice

  const {
    show: showInvoiceConfirmationDialog,
    hide: hideInvoiceConfirmationDialog,
    visible: isInvoiceConfirmationDialogVisible,
  } = useDialogVisibility('invoice-confirmation')

  return (
    <ContentContainerBackoffice>
      <BackofficeSectionTitle>Nouvelle facture V2</BackofficeSectionTitle>

      <div
        css={css`
          width: 800px;
        `}
      >
        <Card
          fullwidth
          withBorder
          title="Données de facturation"
          subtitle="Les inputs nécessaires pour créer une facture."
          avatar={null}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: ${spacing[60]};
            `}
          >
            <div
              css={css`
                display: flex;
                justify-content: flex-start;
                gap: ${spacing[60]};
              `}
            >
              <DatepickerFormField
                label="Premier jour"
                onChange={handleFirstPeriodDayCalendarDateChange}
                value={firstPeriodDayCalendarDate}
                size="small"
              />

              <DatepickerFormField
                label="Dernier jour (inclus)"
                value={lastPeriodDayCalendarDate}
                onChange={handleLastPeriodDayCalendarDateChange}
                size="small"
                minDate={firstPeriodDayCalendarDate ?? undefined}
              />
            </div>
            {adjustedInvoice ? (
              <Text>Cette période étant déjà facturée, une facture d’ajustement sera créée.</Text>
            ) : (
              <></>
            )}

            <DropdownFormField
              css={css`
                width: 320px;
              `}
              value={contractVersionId}
              values={contractVersionIds}
              onChange={handleContractVersionChange}
              size="small"
              placeholder="Version du contrat"
              labels={getContractVersionsLabel(contractVersionIdsWithLabel)}
              label="Version du contrat"
            />

            <div
              css={css`
                display: flex;
                gap: ${spacing[60]};
              `}
            >
              <DatepickerFormField
                label="Date limite de paiement"
                value={dueCalendarDate}
                onChange={handleDueCalendarDateChange}
                size="small"
              />

              <DatepickerFormField
                label="Date d'émission"
                value={issueCalendarDate}
                onChange={handleIssueCalendarDateChange}
                size="small"
              />
            </div>

            <div>
              <CheckboxContainer checked={isFirstOfYear} onChange={() => setIsFirstOfYear(!isFirstOfYear)} size="small">
                <Text variant="body2">La période facturée est la première période d&apos;une année contractuelle.</Text>
              </CheckboxContainer>
              <CheckboxContainer checked={isInFirstYear} onChange={() => setIsInFirstYear(!isInFirstYear)} size="small">
                <Text variant="body2">La période facturée est dans la première année contractuelle.</Text>
              </CheckboxContainer>
            </div>

            <div
              css={css`
                display: flex;
              `}
            >
              <TextInputLabelWrapper label="Montant total TTC">
                <div
                  css={css`
                    display: flex;
                    gap: ${spacing[60]};
                  `}
                >
                  <ValidatedTextField
                    value={totalPremium}
                    onChange={handleTotalPremiumChange}
                    mapper={amountMapperWithDecimals}
                    size="small"
                    InputProps={amountCustomInputProps}
                  />
                  {contractVersionId && periodStartTimestamp && periodEndTimestamp && dueTimestamp && issueTimestamp ? (
                    <ComputeTotalPremiumButton
                      contractVersionId={contractVersionId}
                      periodStartTimestamp={periodStartTimestamp}
                      periodEndTimestamp={periodEndTimestamp}
                      organizationType={organizationType}
                      isInFirstYear={isInFirstYear}
                      isFirstOfYear={isFirstOfYear}
                      subscriptionId={subscriptionId}
                      dueTimestamp={dueTimestamp}
                      issueTimestamp={issueTimestamp}
                      setTotalPremium={setTotalPremium}
                      setTheoreticalInvoiceData={setTheoreticalInvoiceData}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </TextInputLabelWrapper>
            </div>

            <div
              css={css`
                display: flex;
                justify-content: flex-end;
              `}
            >
              <Button
                onClick={showInvoiceConfirmationDialog}
                avatarPosition="left"
                disabled={!theoreticalInvoiceData || !totalPremium}
              >
                Créer la facture
              </Button>
            </div>
            {isInvoiceConfirmationDialogVisible &&
            theoreticalInvoiceData &&
            totalPremium &&
            periodStartTimestamp &&
            periodEndTimestamp &&
            issueTimestamp &&
            dueTimestamp ? (
              <InvoiceConfirmationDialog
                onHide={hideInvoiceConfirmationDialog}
                theoreticalInvoiceData={theoreticalInvoiceData}
                totalPremium={totalPremium}
                periodStartTimestamp={periodStartTimestamp}
                periodEndTimestamp={periodEndTimestamp}
                issueTimestamp={issueTimestamp}
                dueTimestamp={dueTimestamp}
                adjustedInvoiceId={adjustedInvoice?.invoiceId}
                existingInvoices={existingInvoices}
              />
            ) : (
              <></>
            )}
          </div>
        </Card>
      </div>
    </ContentContainerBackoffice>
  )
}

type ComputeTotalPremiumButtonProps = {
  contractVersionId: string
  periodStartTimestamp: number
  periodEndTimestamp: number
  organizationType: OrganizationType
  isInFirstYear: boolean
  isFirstOfYear: boolean
  subscriptionId: string
  dueTimestamp: number
  issueTimestamp: number
  setTotalPremium: (value: Amount | null) => void
  setTheoreticalInvoiceData: (value: InvoiceDataV2 | null) => void
}

const ComputeTotalPremiumButton = memo(function ComputeTotalPremiumButton({
  contractVersionId,
  periodStartTimestamp,
  periodEndTimestamp,
  organizationType,
  isInFirstYear,
  isFirstOfYear,
  subscriptionId,
  dueTimestamp,
  issueTimestamp,
  setTotalPremium,
  setTheoreticalInvoiceData,
}: ComputeTotalPremiumButtonProps): JSX.Element {
  const theoreticalInvoiceDataQuery = trpcReact.invoices.getTheoreticalInvoiceData.useQuery({
    subscriptionId,
    contractVersionId,
    periodStartTimestamp,
    periodEndTimestamp,
    organizationType,
    isInFirstYear,
    isFirstOfYear,
    dueTimestamp,
    issueTimestamp,
  })

  const theoreticalInvoiceData = theoreticalInvoiceDataQuery.data

  const handleClick = () => {
    if (theoreticalInvoiceData) {
      setTheoreticalInvoiceData(theoreticalInvoiceData)
      setTotalPremium(theoreticalInvoiceData.totalPremium)
    }
  }

  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        align-items: flex-end;
      `}
    >
      <Button
        variant="secondary"
        size="small"
        onClick={handleClick}
        disabled={theoreticalInvoiceDataQuery.isLoading}
        isLoading={theoreticalInvoiceDataQuery.isLoading}
      >
        Calculer le montant total théorique
      </Button>
    </div>
  )
})

export type InvoiceConfirmationDialogProps = {
  onHide: () => void
  periodStartTimestamp: number
  periodEndTimestamp: number
  issueTimestamp: number
  dueTimestamp: number
  totalPremium: Amount
  theoreticalInvoiceData: InvoiceDataV2
  adjustedInvoiceId: string | undefined
  existingInvoices: InvoicingItem[]
}

export const InvoiceConfirmationDialog = memo<InvoiceConfirmationDialogProps>(function InvoiceConfirmationDialog({
  onHide,
  periodStartTimestamp,
  periodEndTimestamp,
  issueTimestamp,
  dueTimestamp,
  totalPremium,
  theoreticalInvoiceData,
  adjustedInvoiceId,
  existingInvoices,
}): JSX.Element {
  const navigate = useNavigate()
  const { subscriptionId } = theoreticalInvoiceData

  const onCreateInvoiceV2 = useAsyncCallback(async () => {
    const invoiceId = adjustedInvoiceId
      ? generateAdjustmentInvoiceId(
          adjustedInvoiceId,
          existingInvoices.map(({ invoice }) => invoice).filter((invoice) => 'periodStartTimestamp' in invoice),
        )
      : generateMainInvoiceId(subscriptionId, { periodStartTimestamp, periodEndTimestamp }).toString()

    const payload: CreateInvoiceV2Payload = {
      invoiceId,
      adjustedInvoiceId: adjustedInvoiceId ?? null,
      initialData: scaleInvoiceData(theoreticalInvoiceData, totalPremium),
    }

    await trpc.invoices.createInvoiceV2.mutate(payload)

    enqueueTemporaryNotificationAlert('Facture créée avec succès', { variant: 'success' })
    void navigate({ to: '/bak/contracts/$subscriptionId', params: { subscriptionId }, replace: true })
  }, [
    navigate,
    periodEndTimestamp,
    periodStartTimestamp,
    subscriptionId,
    theoreticalInvoiceData,
    totalPremium,
    adjustedInvoiceId,
    existingInvoices,
  ])

  const lastPeriodDayTimestamp = getZonedDateTimeFromMillis(periodEndTimestamp, PARIS).minus({ day: 1 }).toMillis()

  return (
    <Dialog
      onClose={onHide}
      title={'Vérification de la facture'}
      size="small"
      onSecondaryAction={onHide}
      secondaryActionLabel="Annuler"
      primaryActionLabel="Confirmer"
      onPrimaryAction={onCreateInvoiceV2}
    >
      <div
        css={css`
          width: 100%;
        `}
      >
        <ValidationDialogRowContent title={"Date d'émission"} text={formatTimestampDdMmYyyy(issueTimestamp)} />
        <Divider
          orientation="horizontal"
          css={css`
            margin: ${spacing[40]} 0 ${spacing[40]} 0;
          `}
        />
        <ValidationDialogRowContent title={'Date de paiement'} text={formatTimestampDdMmYyyy(dueTimestamp)} />
        <ValidationDialogDivider />
        <ValidationDialogRowContent
          title={'Début de la période'}
          text={formatTimestampDdMmYyyy(periodStartTimestamp)}
        />
        <ValidationDialogDivider />
        <ValidationDialogRowContent
          title={'Fin de la période'}
          text={formatTimestampDdMmYyyy(lastPeriodDayTimestamp)}
        />
        <ValidationDialogDivider />
        <ValidationDialogRowContent
          title={'Montant TTC'}
          text={amountToString(totalPremium, { addCurrency: true, displayDecimals: true })}
        />
      </div>
    </Dialog>
  )
})

function getContractVersionsLabel(contractVersionIdsWithLabel: [string, string][]): { [k: string]: string } {
  return Object.fromEntries(contractVersionIdsWithLabel)
}

function invoicePeriodMatches(invoice: Invoice, period: Period): boolean {
  const invoicePeriod: Period = 'items' in invoice ? invoice.items[0] : invoice
  return (
    invoicePeriod.periodStartTimestamp === period.periodStartTimestamp &&
    invoicePeriod.periodEndTimestamp === period.periodEndTimestamp
  )
}

function isAdjustementInvoice(invoice: Invoice): boolean {
  return !('items' in invoice) && !!invoice.adjustedInvoiceId
}
